body,
html {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
  padding-top: 40px;
}
h2,
h3,
h4 {
  color: #000;
}
h2 {
  margin: 0 0 20px;
  font-weight: 500;
  font-size: 36px;
}
h3 {
  font-size: 20px;
  font-weight: 500;
}
h4 {
  font-size: 16px;
  font-weight: 500;
}
h5 {
  font-weight: 700;
  line-height: 20px;
}
p {
  font-size: 15px;
}
p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}
a {
  color: #608dfd;
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #608dfd;
}
ul,
ol {
  list-style: none;
}
ul,
ol {
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}
hr {
  height: 2px;
  width: 70px;
  text-align: center;
  position: relative;
  background: #349875;
  margin-bottom: 20px;
  border: 0;
}
/* Navigation */
#menu {
  padding: 15px;
  transition: all 0.8s;
}
#menu.navbar-default {
  background-color: #fff;
  border-color: rgb(226, 229, 232);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
#menu a.navbar-brand {
  font-size: 24px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
#menu.navbar-default .navbar-nav > li > a {
  color: #2c3e50;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
}
#menu.navbar-default .navbar-nav > li > a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: #349875;
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
  width: 100%;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a:after,
.navbar-default .navbar-nav > .active > a:hover:after,
.navbar-default .navbar-nav > .active > a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: #349875 !important;
  content: "" !important;
  transition: width 0.2s !important;
}
.navbar-toggle {
  border-radius: 0;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #349875;
}
.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: #349875;
}
.navbar-nav .new-badge {
  position: absolute;
  right: 0px;
  padding: 2px;
  font-size: 11px;
}
.section-title {
  margin-bottom: 15px;
  text-align: left;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  color: #000;
  text-align: left;
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: #349875;
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: 0;
  left: 0;
}
.section-title p {
  font-size: 18px;
}
.btn-custom {
  color: rgb(51, 51, 51);
  background-color: rgb(226, 229, 232);
  padding: 12px 30px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.5s linear;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  background-image: none;
  background-color: #fff;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}
/* Header Section */
.intro {
  display: table;
  width: 100%;
  background: url(../public/img/intro_bg.jpg) center center no-repeat;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
.intro .overlay {
  background: rgba(0, 0, 0, 0.2);
}
.intro-text-content {
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
}
.intro h1 {
  font-family: 'Lexend', sans-serif;
  color: #fff;
  font-size: 70px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.intro h1 small {
  color: #fff;
  font-size: 52px;
}
.intro h1 span {
  font-weight: 800;
  color: #66d1c2;
}
.intro p {
  color: #fff;
  font-size: 26px;
  font-weight: 400;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-family: 'Lexend', sans-serif;
}
header .intro-text {
  padding-top: 350px;
  padding-bottom: 300px;
}
/* Features Section */
#features {
  padding: 100px 0;
  background: #f8f9fa;
}

.features-tabs {
  max-width: 1200px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.features-nav {
  display: flex;
  background: #f8f9fa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 1rem;
  gap: 1rem;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  flex-wrap: wrap;
}

.feature-tab {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 1.5rem;
  border: none;
  background: transparent;
  color: #000;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  border-radius: 8px;
}

.feature-tab i {
  font-size: 1.25rem;
  color: #349875;
}

.feature-tab:hover {
  background: rgba(52, 152, 117, 0.1);
  color: #349875;
}

.feature-tab.active {
  background: #349875;
  color: #ffffff;
}

.feature-tab.active i {
  color: #ffffff;
}

.feature-content {
  padding: 2.5rem;
  text-align: left;
}

.feature-header {
  margin-bottom: 2rem;
}

.feature-header-title {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.feature-header h3 {
  font-size: 2.25rem;
  color: #2c3e50;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.feature-description {
  font-size: 1.8rem;
  line-height: 1.8;
  color: #4a5568;
  margin-bottom: 2rem;
}

.feature-action-button {
  background-color: rgb(89, 116, 192);
  color: white;
  padding: 7px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.feature-action-button:hover {
  background-color: rgb(226, 229, 232);
  color: rgb(89, 116, 192);
}

.feature-details {
  padding: 1.5rem 0;
}

.feature-bullet-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
  padding: 1.5rem 3rem;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.feature-bullet-details h5 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.75rem;
}

.feature-bullet-details p {
  font-size: 1.4rem;
  line-height: 1.8;
  color: #4a5568;
}

@media (max-width: 768px) {
  .features-nav {
    flex-direction: column;
  }

  .feature-tab {
    margin-bottom: 10px;
    width: 100%;
  }

  .feature-content {
    padding: 1.5rem;
  }

  .feature-header h3 {
    font-size: 1.6rem;
  }

  .feature-description {
    font-size: 1.1rem;
  }

  .feature-bullet-details h5 {
    font-size: 1.3rem;
  }

  .feature-bullet-details p {
    font-size: 1.1rem;
  }
}
/* About Section */
#about {
  padding: 100px 0;
  background: #ffffff;
}

#about .about-text {
  max-width: 1000px;
  margin: 0 auto;
}

#about h2 {
  font-size: 3.5rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 2.5rem;
  padding-bottom: 1.5rem;
  position: relative;
}

#about h2::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100px;
  height: 4px;
  background: #349875;
  border-radius: 2px;
}

#about p {
  font-size: 1.8rem;
  line-height: 1.8;
  color: #4a5568;
  margin-bottom: 2rem;
}

#about ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 2.5rem;
}

#about ul li {
  font-size: 1.4rem;
  line-height: 1.8;
  color: #4a5568;
  margin-bottom: 1.5rem;
  padding-left: 2.5rem;
  position: relative;
}

#about ul li:before {
  content: "•";
  color: #349875;
  position: absolute;
  left: 0;
  font-weight: bold;
  font-size: 1.6rem;
}

#about #ecovate-container {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 3rem;
  margin: 4rem 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  border: none;
}

#about #ecovate-container {
  border-left: 4px solid #349875;
}

#about h3 {
  font-size: 2.25rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 2rem;
  padding-top: 0;
}

#about .about-bullet-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
  padding: 1.5rem 3rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

#about .about-bullet-details h5 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.75rem;
}

#about .about-bullet-details p {
  font-size: 1.4rem;
  line-height: 1.8;
  color: #4a5568;
}

#about #ecovate-logo-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
}

#about .ecovate-btn:hover {
  color: #fff;
  background-color: #349875;
}

@media (max-width: 768px) {
  #about {
    padding: 60px 0;
  }

  #about h2 {
    font-size: 2.75rem;
  }

  #about h3 {
    font-size: 2rem;
  }

  #about p, 
  #about ul li {
    font-size: 1.2rem;
  }

  #about #why-dl-container,
  #about #ecovate-container {
    padding: 2rem;
  }

  #about .about-bullet-details h5 {
    font-size: 1.3rem;
  }

  #about .about-bullet-details p {
    font-size: 1.1rem;
  }

  #about #ecovate-logo-container {
    flex-direction: column;
    gap: 1.5rem;
    text-align: center;
  }

  #about .ecovate-btn {
    font-size: 1.1rem;
    padding: 1rem 2rem;
  }
}
/* Contact Section */
#contact {
  padding: 75px;
  background: #349875;
  color: rgba(255, 255, 255, 0.75);
}
#contact h2 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#contact .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
}
#contact h3 {
  color: #fff;
  margin-top: 0;
  padding-bottom: 10px;
  font-weight: 400;
}
#contact form {
  padding-top: 10px;
}
#contact .text-danger {
  color: #cc0033;
  text-align: left;
}
label {
  font-size: 12px;
  font-weight: 400;
  float: left;
}
#contact .form-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
#contact .form-control:focus {
  border-color: #999;
  outline: 0;
  -webkit-box-shadow: transparent;
  box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
  color: #777;
}
.form-control:-moz-placeholder {
  color: #777;
}
.form-control::-moz-placeholder {
  color: #777;
}
.form-control:-ms-input-placeholder {
  color: #777;
}
#contact .contact-item {
  padding-bottom: 8px;
}
#contact .contact-item span {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 5px;
  display: block;
}
#contact .contact-item i.fa {
  margin-right: 10px;
}
#contact #success {
  margin-top: 5px;
}
/* Footer Section*/
#footer {
  background: #54595F;
  padding: 17px 0 10px 0;
}
#footer p {
  color: white;
  font-size: 14px;
}
#footer a {
  color: #349875;
}
#footer a:hover {
  border-bottom: 2px solid #349875;
}
.new-badge {
  background-color: rgb(255, 128, 0);
  color: #fff;
  font-weight: 500;
  padding: 3px;
  border-radius: 4px;
}

.coming-soon-badge {
  background-color: rgb(255, 128, 0);
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 1.2rem;
}

/* Sign Up Banner */
.signup-banner {
  position: fixed;
  width: 100%;
  background-color: rgb(89, 116, 192);
  color: white;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  z-index: 999;
  top: 76px; /* Position right below the navbar */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.signup-banner .container {
  display: flex;
  justify-content: center;
}

.banner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-weight: bold;
  font-size: 16px;
}

.banner-button {
  background-color: white;
  color: black;
  padding: 5px 15px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.banner-button:hover {
  background-color: #f8f8f8;
  color: #3670e0;
  text-decoration: none;
}

@media (max-width: 768px) {
  .banner-content {
    flex-direction: column;
    gap: 10px;
    padding: 5px 0;
  }
  
  .signup-banner {
    padding: 8px 0;
  }
}

/* Video Container Styles */
.video-container {
  margin: 40px 0;
}

.responsive-video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin: 20px 0;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.responsive-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}
